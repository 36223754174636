html,
body {
    height: 100%;
}

#root {
    height: 100%;
    overflow-y: auto;
    position: relative;
}

.sticky {
    position: sticky;
    top: -0.03125rem;
}
